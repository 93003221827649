import axios from 'axios'

export const Domain = {
    production: "https://rocket.qarar.me/api/v1/",
    // development: "https://rocket.qarar.me/api/v1/",
    development: "https://curiosity.qarar.me/wp-json/qarar-app/v1/",
    // development: "http://qarar-api.local/wp-json/qarar-app/v1/",
}

const apiWithoutInterceptor = axios.create({
    baseURL: `${process.env.NODE_ENV === "production"
        ? Domain.production
        : Domain.development
        }`,
    headers: {
        "Content-Type": "multipart/form-data",
    },
});

const apiWithInterceptor = axios.create({
    baseURL: `${process.env.NODE_ENV === "production"
        ? Domain.production
        : Domain.development
        }`,
    headers: {
        "Content-Type": "multipart/form-data",
        // "Accept": "application/json",
    },
});

[apiWithInterceptor, apiWithoutInterceptor].forEach((api) => {
    api.interceptors.request.use(async (config) => {
        const locale = localStorage.getItem("locale") || "en";
        const path = config.url?.replace(Domain.production, "").replace(Domain.development, "");
        config.url = process.env.NODE_ENV === "production" ? `${Domain.production}${locale}${path}` : `${Domain.development}${locale}${path}`;
        return config;
    });
});

apiWithInterceptor.interceptors.request.use(async (config) => {
    let token = localStorage?.getItem("token")?.replace(/['"]+/g, "");

    if (token) {
        if (!config.headers) config.headers = {} as any;
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

// filter all responses and check if the status is 200 and the key success is true then return the data
[apiWithInterceptor, apiWithoutInterceptor].forEach((api) => {
    api.interceptors.response.use((response) => {
        if (response.status === 200 && response.data.status === "success") {
            return response.data.data;
        }
        return response;
    }, (error) => {
        return Promise.reject(error);
    });
});

export { apiWithInterceptor, apiWithoutInterceptor };