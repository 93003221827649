import { Badge, Card, Flex, Grid, Text } from "@mantine/core";
import { IconArrowsExchange2, IconEyeCheck, IconTargetArrow } from "@tabler/icons-react";
import { useQueryRealtime, useQueryViewsChart, useQueryVotesChart } from "api/hook/analysis";
import { Area } from '@ant-design/plots';
import { DatePickerInput } from "@mantine/dates";
import { useEffect, useState } from "react";
import dayjs from "dayjs";

export default function Dashboard() {

    const reltimeQuery = useQueryRealtime();

    return (
        <Flex
            direction="column"
            sx={theme => ({
                '& > *:not(:last-child)': {
                    borderBottom: `1px solid ${theme.colors.gray[8]}`
                }
            })}
        >
            <Grid
                gutter={0}
                sx={theme => ({
                    '& > *:not(:last-child)': {
                        borderRight: `1px solid ${theme.colors.gray[8]}`
                    },
                })}
            >
                <Grid.Col span={4}>
                    <Card>
                        <Flex direction="column" gap="md" align="center" py={'xl'}>
                            <IconEyeCheck size={70} />
                            <Badge color="blue" variant="light" size="xl">
                                {reltimeQuery.data?.views}
                            </Badge>
                            <Text size="md" weight={200}>
                                views In the last {reltimeQuery.data?.minutes} minutes
                            </Text>
                        </Flex>
                    </Card>
                </Grid.Col>
                <Grid.Col span={4}>
                    <Card>
                        <Flex direction="column" gap="md" align="center" py={'xl'}>
                            <IconTargetArrow size={70} />
                            <Badge color="blue" variant="light" size="xl">
                                {reltimeQuery.data?.votes}
                            </Badge>
                            <Text size="md" weight={200}>
                                votes In the last {reltimeQuery.data?.minutes} minutes
                            </Text>
                        </Flex>
                    </Card>
                </Grid.Col>
                <Grid.Col span={4}>
                    <Card>
                        <Flex direction="column" gap="md" align="center" py={'xl'}>
                            <IconArrowsExchange2 size={70} />
                            <Badge color="blue" variant="light" size="xl">
                                {reltimeQuery.data?.votes}
                            </Badge>
                            <Text size="md" weight={200}>
                                unvotes In the last {reltimeQuery.data?.minutes} minutes
                            </Text>
                        </Flex>
                    </Card>
                </Grid.Col>
            </Grid>
            <Grid gutter={0}>
                <Grid.Col span={12}>
                    <ViewsChart />
                </Grid.Col>
            </Grid>
            <Grid gutter={0}>
                <Grid.Col span={12}>
                    <VotesChart />
                </Grid.Col>
            </Grid>
        </Flex>
    );

}

const ViewsChart = () => {

    const [value, setValue] = useState<[Date | null, Date | null]>([
        dayjs().subtract(7, 'day').toDate(),
        dayjs().toDate(),
    ]);
    const [dateRange, setDateRange] = useState<[string, string]>([
        dayjs().subtract(7, 'day').format('YYYY-MM-DD'),
        dayjs().format('YYYY-MM-DD'),
    ]);

    useEffect(() => {
        if (value[0] && value[1]) {
            setDateRange([
                dayjs(value[0]).format('YYYY-MM-DD'),
                dayjs(value[1]).format('YYYY-MM-DD'),
            ]);
        }
    }, [value]);

    const data = useQueryViewsChart({
        after: dateRange[0],
        before: dateRange[1],
    });

    const config = {
        data: data.data as any,
        xField: 'date',
        yField: 'views',
        smooth: true,
        theme: "dark",
        style: {
            borderRadius: 10,
            overflow: 'hidden',
        },
        padding: 50,
        xAxis: {
            range: [0, 1],
        },
    };

    return (
        <Card padding="xl" sx={{ overflow: 'visible' }}>
            <Flex direction="column">
                <Flex justify="space-between">
                    <Text size="xl" weight={700} mt={'xl'} mb={'md'}>
                        Views
                    </Text>
                    <DatePickerInput
                        type="range"
                        placeholder="Pick dates"
                        value={value}
                        onChange={setValue}
                        label=" "
                    />
                </Flex>
                {
                    data.data ? (
                        <Area {...config} />
                    ) : (
                        <Card
                            sx={{
                                backgroundColor: '#141414',
                                borderRadius: 10,
                                textAlign: 'center',
                            }}
                        >
                            <Text size="md" weight={200}>
                                No data
                            </Text>
                        </Card>
                    )
                }
            </Flex>
        </Card>
    )
};
const VotesChart = () => {

    const [value, setValue] = useState<[Date | null, Date | null]>([
        dayjs().subtract(7, 'day').toDate(),
        dayjs().toDate(),
    ]);
    const [dateRange, setDateRange] = useState<[string, string]>([
        dayjs().subtract(7, 'day').format('YYYY-MM-DD'),
        dayjs().format('YYYY-MM-DD'),
    ]);

    useEffect(() => {
        if (value[0] && value[1]) {
            setDateRange([
                dayjs(value[0]).format('YYYY-MM-DD'),
                dayjs(value[1]).format('YYYY-MM-DD'),
            ]);
        }
    }, [value]);

    const data = useQueryVotesChart({
        after: dateRange[0],
        before: dateRange[1],
    });

    const config = {
        data: data.data as any,
        xField: 'date',
        yField: 'views',
        smooth: true,
        theme: "dark",
        style: {
            borderRadius: 10,
            overflow: 'hidden',
        },
        padding: 50,
        xAxis: {
            range: [0, 1],
        },
    };

    return data.data && (
        <Card padding="xl" sx={{ overflow: 'visible' }}>
            <Flex direction="column">
                <Flex justify="space-between">
                    <Text size="xl" weight={700} mt={'xl'} mb={'md'}>
                        Votes
                    </Text>
                    <DatePickerInput
                        type="range"
                        placeholder="Pick dates"
                        value={value}
                        onChange={setValue}
                        label=" "
                    />
                </Flex>
                {
                    data.data ? (
                        <Area {...config} />
                    ) : (
                        <Card
                            sx={{
                                backgroundColor: '#141414',
                                borderRadius: 10,
                                textAlign: 'center',
                            }}
                        >
                            <Text size="md" weight={200}>
                                No data
                            </Text>
                        </Card>
                    )
                }
            </Flex>
        </Card>
    );
};
