import { apiWithInterceptor } from './';
import axios, { CancelTokenSource } from 'axios';

let cancelTokens: { [key: string]: CancelTokenSource } = {};
export const getUsers = async (data: {
    offset: number,
    limit: number,
    search?: string,
    orderby?: string,
    order?: 'ASC' | 'DESC',
    role?: string,
}) : Promise<any> => {
    cancelTokens.getUsers && cancelTokens.getUsers.cancel();
    cancelTokens.getUsers = axios.CancelToken.source();
    return apiWithInterceptor.get("/users", {
        params: Object.assign({
            offset: 0,
            limit: 10,
            order: 'DESC',
            orderby: 'registered',
        }, data),
    });
};


export const newUser = async ({
    name,
    email,
    password,
}: {
    name: string,
    email: string,
    password: string,
}) => apiWithInterceptor.post("/users", {
    name,
    email,
    password,
});

export const updateUser = async ({
    id,
    name,
    email,
    password,
}: {
    id: number,
    name: string,
    email: string,
    password?: string,
}) => apiWithInterceptor.patch(`/users`, {
    id,
    name,
    email,
    ...(password && { password }),
});

export const deleteUser = async ({
    id,
}: {
    id: number,
}) => apiWithInterceptor.delete(`/users/${id}`);