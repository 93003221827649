import { Button, Grid, LoadingOverlay, NumberInput, PasswordInput, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconArrowBackUp, IconAsterisk, IconAt, IconMoodEmpty, IconMoodSmileBeam } from '@tabler/icons';
import { PasswordStrength } from 'components';
import { CenterFocus, WarperLoginForm } from 'layout';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Login() {

    const navigate = useNavigate();
    const [stage, setStage] = useState<'register' | 'verify'>('register');

    const formRegister = useForm({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            passwordConfirm: '',
            passwordStrength: 0,
        },
        validate: {
            firstName: (value) => (value.length < 3 ? "Invalid name" : null),
            lastName: (value) => (value.length < 3 ? "Invalid name" : null),
            email: (value) => (value.length < 5 || !value.match(/^[A-Z0-9._-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i) ? "Invalid email address" : null),
            password: (value) => (value.length < 8 ? "Invalid password" : null),
        }
    });

    const formVerify = useForm({
        initialValues: {
            code: '',
            email: '',
        },
    });

    return (
        <CenterFocus>
            <WarperLoginForm>
                {
                    stage === 'register' && (
                        <form
                            onSubmit={formRegister.onSubmit(values => {

                                if (values.password !== values.passwordConfirm) {
                                    formRegister.setErrors({
                                        passwordConfirm: "Passwords do not match",
                                    });
                                    return;
                                }

                                if (values.passwordStrength < .5) {
                                    formRegister.setErrors({
                                        password: "Password is too weak",
                                    });
                                    return;
                                }

                                setStage('verify');
                            })}
                        >
                            <Grid
                                style={{
                                    maxWidth: 300,
                                    margin: '0 auto',
                                }}
                            >
                                <LoadingOverlay visible={false} overlayBlur={2} />
                                <Grid.Col span={12}>
                                    <Button
                                        onClick={() => navigate('/')}
                                        leftIcon={<IconArrowBackUp size={20} />}
                                        variant="subtle"
                                        fullWidth
                                    >
                                        {"Back"}
                                    </Button>
                                </Grid.Col>
                                <Grid.Col span={6}>
                                    <TextInput
                                        placeholder={"First name" as string}
                                        icon={<IconMoodSmileBeam size={20} />}
                                        {...formRegister.getInputProps('firstName')}
                                    />
                                </Grid.Col>
                                <Grid.Col span={6}>
                                    <TextInput
                                        placeholder={"Last name" as string}
                                        icon={<IconMoodEmpty size={20} />}
                                        {...formRegister.getInputProps('lastName')}
                                    />
                                </Grid.Col>
                                <Grid.Col span={12}>
                                    <TextInput
                                        placeholder={"Email" as string}
                                        icon={<IconAt size={20} />}
                                        {...formRegister.getInputProps('email')}
                                    />
                                </Grid.Col>
                                <Grid.Col span={12}>
                                    <PasswordInput
                                        placeholder={"Password" as string}
                                        icon={<IconAsterisk size={20} />}
                                        {...formRegister.getInputProps('password')}
                                    />
                                </Grid.Col>
                                <Grid.Col span={12}>
                                    <PasswordInput
                                        placeholder={"Confirm Password" as string}
                                        icon={<IconAsterisk size={20} />}
                                        {...formRegister.getInputProps('passwordConfirm')}
                                    />
                                </Grid.Col>
                                <Grid.Col span={12}>
                                    <PasswordStrength
                                        value={formRegister.values.password}
                                        onChange={(value) => {
                                            formRegister.setFieldValue('passwordStrength', value);
                                        }}
                                    />
                                </Grid.Col>
                                <Grid.Col span={12}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="gradient"
                                    >
                                        {"Register"}
                                    </Button>
                                </Grid.Col>
                            </Grid>
                        </form>
                    )
                }

                {
                    stage === 'verify' && (
                        <form
                            onSubmit={formVerify.onSubmit(values => {
                                console.log(values);
                            })}
                        >
                            <Grid
                                style={{
                                    maxWidth: 300,
                                    margin: '0 auto',
                                }}
                            >
                                <LoadingOverlay visible={false} overlayBlur={2} />
                                <Grid.Col span={12}>
                                    <Text
                                        align="center"
                                    >
                                        {"We have sent a verification code to your email address:" + ' '}
                                        <strong>{formRegister.values.email}</strong>
                                    </Text>
                                </Grid.Col>
                                <Grid.Col span={12}>
                                    <NumberInput
                                        placeholder={"Code" as string}
                                        icon={<IconAsterisk size={20} />}
                                        {...formVerify.getInputProps('code')}
                                    />
                                </Grid.Col>
                                <Grid.Col span={12}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="gradient"
                                    >
                                        {"Verify Code"}
                                    </Button>
                                </Grid.Col>
                            </Grid>
                        </form>
                    )
                }
            </WarperLoginForm>
        </CenterFocus>
    );

}