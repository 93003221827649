
import { Badge, Box, Drawer, Flex, Text, Title } from '@mantine/core'
import { useDisclosure, useToggle } from '@mantine/hooks'
import { IconPlus } from '@tabler/icons-react'
import {
    createColumnHelper,
} from '@tanstack/react-table'
import { getOpinions } from 'api/fetch/opinion'
// import { useQueryOpinion } from 'api/hook/opinions'
import { Opinion } from 'api/types'
import { CategoriesPills, PageHead, Table, VoteResult } from 'components'
import dayjs from 'dayjs'
import { OpinionEdit, OpinionNew } from 'pages'
import { useState } from 'react'


const columnHelper = createColumnHelper<Opinion>()

const getFirstAvailableTitle = (titles: (string | null | undefined)[]) => {
    for (const title of titles) {
        if (title) {
            return title;
        }
    }
    return '';
}

const columns = [
    columnHelper.accessor(row => row.title_ar, {
        id: 'title_ar',
        enableSorting: false,
        cell: info => (
            <Flex
                direction="column"
                py={'xs'}
                sx={theme => ({
                    minWidth: 400,
                    paddingRight: '5%',
                    '& > *': {
                        position: 'relative',
                        width: '100%',
                    },
                    '& > *:before': {
                        content: '""',
                        position: 'absolute',
                        left: 0,
                        width: '100%',
                        height: '100%',
                    },
                    '& > *:nth-child(1):before': {
                        borderRadius: '0 20px 0 0',
                        border: `1px solid ${theme.colors.dark[6]}`,
                        borderBottom: 'none',
                        borderLeft: 'none',
                        top: '50%',
                    },
                    '& > *:nth-child(2):before': {
                        borderTop: `1px solid ${theme.colors.dark[6]}`,
                        width: '105%',
                        top: '50%',
                    },
                    '& > *:nth-child(3):before': {
                        borderRadius: '0 0 20px 0',
                        border: `1px solid ${theme.colors.dark[6]}`,
                        borderTop: 'none',
                        borderLeft: 'none',
                        bottom: '50%',
                    },
                })}
            >
                {
                    [
                        info.row.original.title_ar || '',
                        info.row.original.title_krd || '',
                        info.row.original.title_en || '',
                    ].map((title, index) => {

                        let notAvailable = false;
                        if (!title) {
                            notAvailable = true;
                            title = getFirstAvailableTitle([info.row.original.title_ar, info.row.original.title_krd, info.row.original.title_en])
                        }

                        return (
                            <Box
                                key={index}
                                sx={theme => ({
                                    width: '100%',
                                })}
                            >
                                <Title
                                    order={4}
                                    style={{ lineHeight: 1.2 }}
                                    px="xs"
                                    py={3}
                                    sx={theme => ({
                                        backgroundColor: theme.colors.dark[7],
                                        position: 'relative',
                                        margin: '0 50px 0 0',
                                        display: 'block',
                                        width: 'max-content',
                                        ...(notAvailable && {
                                            color: theme.colors.red[6],
                                        }),
                                    })}
                                >
                                    {title}
                                    <Badge variant="light" ml="xs" sx={theme => ({
                                        ...(notAvailable && {
                                            backgroundColor: theme.colors.red[6],
                                            color: theme.colors.red[1],
                                        }),
                                    })}>
                                        {
                                            index === 0 ? 'ar' :
                                                index === 1 ? 'krd' :
                                                    index === 2 ? 'en' : ''
                                        }
                                    </Badge>
                                </Title>
                            </Box>
                        );
                    })
                }
            </Flex>
        ),
        header: () => <span>Title</span>,
        footer: info => info.column.id,
    }),
    columnHelper.accessor(row => row.categories, {
        id: 'categories',
        enableSorting: false,
        cell: info => <CategoriesPills categories={info.row.original.categories} />,
        header: () => <span>Categories</span>,
        footer: info => info.column.id,
    }),
    columnHelper.accessor(row => row.votes, {
        id: 'votes_result',
        enableSorting: false,
        cell: info => <VoteResult results={info.row.original.votes} />,
        header: () => <span>Result</span>,
        footer: info => info.column.id,
    }),
    columnHelper.accessor(row => row.views, {
        id: 'views',
        cell: info => <Text>{info.row.original.views}</Text>,
        header: () => <span>Views</span>,
        footer: info => info.column.id,
    }),
    columnHelper.accessor(row => row.votes, {
        id: 'votes',
        cell: info => <Text>{info.row.original.votes?.total}</Text>,
        header: () => <span>Votes</span>,
        footer: info => info.column.id,
    }),
    columnHelper.accessor(row => row.comment_count, {
        id: 'comment_count',
        cell: info => <Text>{info.row.original.comment_count}</Text>,
        header: () => <span>Comments</span>,
        footer: info => info.column.id,
    }),
    columnHelper.accessor(row => row.date, {
        id: 'date',
        cell: info => <Text>{dayjs(info.row.original.date).format('YYYY-MM-DD HH:mm A')}</Text>,
        header: () => <span>Date</span>,
        footer: info => info.column.id,
    }),
]


export default function Opinions() {

    const [openedNew, { open: openNew, close: closeNew }] = useDisclosure(false);
    const [openedEdit, { open: openEdit, close: closeEdit }] = useDisclosure(false);
    const [toggleKey, toggleData] = useToggle([true, false]);

    const [selectedOpinion, setSelectedOpinion] = useState<Opinion | null>(null);

    return (
        <Box>
            <PageHead
                title="Opinions"
                links={[
                    {
                        label: 'Create',
                        // href: 'new',
                        onClick: openNew,
                        icon: <IconPlus size={20} />,
                    }
                ]}
            />
            <Table
                endpoint={async (params: {
                    pageIndex: number,
                    pageSize: number,
                    sorting: any,
                    search: string,
                }) => {
                    const data = await getOpinions({
                        offset: params.pageIndex * params.pageSize,
                        limit: params.pageSize,
                        order: params.sorting[0]?.desc === true ? 'DESC' : params.sorting[0]?.desc === false ? 'ASC' : undefined,
                        orderby: params.sorting[0]?.id || 'date',
                        search: params.search,
                    });
                    // if (selectedOpinion) {
                    //     const opinion = data.data.opinions.find((opinion: Opinion) => opinion.id === selectedOpinion.id);                        
                    //     if (opinion) {
                    //         setSelectedOpinion(null);
                    //         setSelectedOpinion(opinion);
                    //     }
                    // }
                    return {
                        data: data.opinions,
                        total: data.total,
                    };
                }}
                cacheKey="opinions"
                columns={columns}
                defaultPageSize={20}
                toggleData={toggleKey}
                onRowClick={(row) => {
                    setSelectedOpinion(row.original);
                    openEdit();
                }}
            />
            <Drawer opened={openedNew} onClose={closeNew} overlayProps={{ opacity: 0.5 }} title="New Opinion">
                <OpinionNew
                    onUpdate={() => {
                        toggleData();
                        closeNew();
                    }}
                />
            </Drawer>

            <Drawer opened={openedEdit} onClose={closeEdit} overlayProps={{ opacity: 0.5 }} title="Edit Opinion">
                {
                    selectedOpinion && (
                        <OpinionEdit
                            opinion={selectedOpinion}
                            onUpdate={() => {
                                toggleData();
                                closeEdit();
                            }}
                        />
                    )
                }
            </Drawer>

        </Box>
    );

}