import { Avatar, Box, Button, Card, Flex, Grid, LoadingOverlay, Select, Text, TextInput, Title } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useQueryAccount } from "api/hook/account";
import RoleBadge from "components/RoleBadge";
import { useEffect, useRef, useState } from "react";
import { DateInput } from '@mantine/dates';
import { updateAccount, updateAccountPicture } from "api/fetch/account";
import { parseErrorMsg } from "utils";
import dayjs from "dayjs";
import { IconEditCircle } from "@tabler/icons-react";

export default function Profile() {

    const [loading, setLoading] = useState(false);

    const {
        data: user,
        refetch
    } = useQueryAccount();

    const form = useForm({
        initialValues: {},
    });

    useEffect(() => {
        if (user) {
            setLoading(false);
            user.name && form.setFieldValue('name', user.name);
            user.email && form.setFieldValue('email', user.email);
            user.birth_date && form.setFieldValue('birth_date', dayjs(user.birth_date).toDate());
            user.phone_number && form.setFieldValue('phone_number', user.phone_number);
            user.education && form.setFieldValue('education', user.education);
            user.gender && form.setFieldValue('gender', user.gender);
            user.work && form.setFieldValue('work', user.work);
        }
    }, [user]);

    const [hoverAvatar, setHoverAvatar] = useState<boolean>(false);
    const profilePictureRef = useRef<HTMLInputElement>(null);

    return (
        <Flex
            direction="column"
        >
            <Card>
                <Flex gap={'md'}>
                    <Flex
                        sx={{
                            position: 'relative',
                        }}
                    >
                        <input type="file" accept="image/*" style={{ display: 'none' }} ref={profilePictureRef} id="picture" onChange={(e) => {
                            if (e.target.files && e.target.files[0]) {
                                const file = e.target.files[0];
                                setLoading(true);
                                updateAccountPicture({
                                    picture: file,
                                }).then(() => {
                                    refetch();
                                }).catch((err) => {
                                    alert(parseErrorMsg(err));
                                }).finally(() => {
                                    setLoading(false);
                                });
                            }
                        } } />
                        <Avatar
                            src={user?.profile_picture?.medium.url}
                            size={100}
                            radius={999}
                            color="blue"
                            onMouseEnter={() => setHoverAvatar(true)}
                            onMouseLeave={() => setHoverAvatar(false)}
                        >
                            <LoadingOverlay visible={loading} />
                            {user?.name?.split(' ').map((name) => name[0]).join('')}
                        </Avatar>
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                width: '100%',
                                height: '100%',
                                borderRadius: 999,
                                backgroundColor: 'rgba(0,0,0,0.5)',
                                opacity: hoverAvatar ? 1 : 0,
                                transition: 'opacity 0.3s ease',
                                cursor: 'pointer',
                            }}
                            onMouseEnter={() => setHoverAvatar(true)}
                            onMouseLeave={() => setHoverAvatar(false)}
                            onClick={() => {
                                if (profilePictureRef.current) {
                                    profilePictureRef.current.click();
                                }
                            }}
                        >
                            <IconEditCircle size={30} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} color="white" />
                        </Box>
                    </Flex>
                    <Flex direction="column" gap={5} justify="center">
                        <RoleBadge role={user?.role} />
                        <Title>{user?.name}</Title>
                        <Text>{user?.email}</Text>
                    </Flex>
                </Flex>
            </Card>
            <form
                onSubmit={
                    form.onSubmit((values) => {
                        setLoading(true);
                        updateAccount(values).then(() => {
                            refetch();
                        }).catch((err) => {
                            alert(parseErrorMsg(err));
                        }).finally(() => {
                            setLoading(false);
                        });
                    })
                }
            >
                <Grid gutter={'md'} p={'md'}>
                    <Grid.Col xs={12} md={6}>
                        <TextInput
                            disabled={loading}
                            label="Name"
                            placeholder="Your name"
                            {...form.getInputProps('name')}
                        />
                    </Grid.Col>
                    <Grid.Col xs={12} md={6}>
                        <TextInput
                            disabled={loading}
                            label="Email"
                            placeholder="Your email"
                            {...form.getInputProps('email')}
                        />
                    </Grid.Col>
                    <Grid.Col xs={12} md={6}>
                        <DateInput
                            disabled={loading}
                            label="Birthdate"
                            placeholder="Select date"
                            {...form.getInputProps('birth_date')}
                        />
                    </Grid.Col>
                    <Grid.Col xs={12} md={6}>
                        <TextInput
                            disabled={loading}
                            label="Phone"
                            placeholder="Your phone number"
                            {...form.getInputProps('phone_number')}
                        />
                    </Grid.Col>
                    <Grid.Col xs={12} md={6}>
                        <Select
                            disabled={loading}
                            label="Education"
                            placeholder="Your education"
                            data={[
                                { value: "primary", label: "Primary" },
                                { value: "high_school", label: "High School" },
                                { value: "bachelor", label: "Bachelor" },
                                { value: "master", label: "Master" },
                                { value: "phd", label: "PhD" },
                                { value: "other", label: "Other" },
                            ]}
                            {...form.getInputProps('education')}
                        />
                    </Grid.Col>
                    <Grid.Col xs={12} md={6}>
                        <Select
                            disabled={loading}
                            label="Gender"
                            placeholder="Select"
                            data={[
                                { value: "male", label: "Male" },
                                { value: "female", label: "Female" },
                            ]}
                            {...form.getInputProps('gender')}
                        />
                    </Grid.Col>
                    <Grid.Col xs={12} md={6}>
                        <TextInput
                            disabled={loading}
                            label="Work"
                            placeholder="Your work"
                            {...form.getInputProps('work')}
                        />
                    </Grid.Col>
                    <Grid.Col xs={12}>
                        <Button type="submit" color="blue" loading={loading}>
                            Update
                        </Button>
                    </Grid.Col>
                </Grid>
            </form>
        </Flex>
    );

}