import { Alert, Box } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";

export default function ComingSoon() {

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                width: '100%',
            }}
        >
            <Alert icon={<IconAlertCircle size="1rem" />} title="Coming Soon!">
                This page could be available in future updates. Stay tuned!
            </Alert>
        </Box>
    );

}