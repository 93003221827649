import { apiWithInterceptor } from './';
import axios, { CancelTokenSource } from 'axios';

let cancelTokens: { [key: string]: CancelTokenSource } = {};

export const getOpinions = async (data: {
    offset?: number,
    limit?: number,
    order?: 'ASC' | 'DESC',
    orderby?: string,
    search?: string,
    post_status?: 'publish' | 'draft' | 'pending',
    cat?: string,
    after?: Date,
    before?: Date,
    voted?: boolean,
    multilang?: boolean,
}): Promise<any> => {
    cancelTokens.getOpinions && cancelTokens.getOpinions.cancel();
    cancelTokens.getOpinions = axios.CancelToken.source();
    return apiWithInterceptor.get("/opinions", {
        // combine params with data make the data override the default params
        params: Object.assign({
            offset: 0,
            limit: 10,
            order: 'DESC',
            orderby: 'date',
            post_status: 'publish',
            multilang: true,
        }, data),
    })
};

export const newOpinion = async ({
    title_ar,
    title_en,
    title_krd,
    categories,
}: {
    title_ar: string | null,
    title_en: string | null,
    title_krd: string | null,
    categories: number[],
}) => apiWithInterceptor.post("/opinions", {
    title_ar: title_ar || null,
    title_en: title_en || null,
    title_krd: title_krd || null,
    categories: categories.join(','),
});

export const updateOpinion = async ({
    id,
    title_ar,
    title_en,
    title_krd,
    categories,
}: {
    id: number,
    title_ar: string | null,
    title_en: string | null,
    title_krd: string | null,
    categories: number[],
}) => apiWithInterceptor.patch(`/opinions/${id}`, {
    title_ar: title_ar || null,
    title_en: title_en || null,
    title_krd: title_krd || null,
    categories: categories.join(','),
});

export const deleteOpinion = async ({
    id,
}: {
    id: number,
}) => apiWithInterceptor.delete(`/opinions/${id}`);