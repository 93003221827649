import { Alert, Button, Flex, LoadingOverlay, PasswordInput, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useLocalStorage, useViewportSize } from '@mantine/hooks';
import { IconAlertCircle, IconAsterisk, IconAt } from '@tabler/icons';
import { getToken, login } from 'api/fetch/auth';
import { CenterFocus, WarperLoginForm } from 'layout';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { parseErrorMsg } from 'utils';

export default function LoginByEmail() {

    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const { width } = useViewportSize();
    const [btnSize, setBtnSize] = useState<'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xl'>('sm');

    const [token, setToken] = useLocalStorage({
        key: 'token',
        defaultValue: '',
    });

    useEffect(() => {
        if (width < 350) {
            setBtnSize('xs');
        } else {
            setBtnSize('sm');
        }
    }, [width]);

    const form = useForm({
        initialValues: {
            email: '',
            password: '',
        },
        validate: {
            email: (value) => (value.length < 5 || !value.match(/^[A-Z0-9._-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i) ? "Invalid email address" : null),
            password: (value) => (value.length < 8 ? "Invalid password" : null),
        }
    });

    useEffect(() => {
        if (token !== "") {
            setLoading(false);
            navigate('/app', { replace: true });
        }
    }, [token]);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const tokenId = params.get('tokenId');
        if (tokenId) {
            setLoading(true);
            getToken({
                tokenId,
            }).then((response) => {
                setToken(response.token);
            }).catch((error) => {
                setErrorMessage(parseErrorMsg(error));
                setLoading(false);
            });
        }
    }, []);

    return (
        <CenterFocus>
            <WarperLoginForm>
                <form
                    onSubmit={form.onSubmit(values => {
                        setErrorMessage("");
                        setLoading(true);

                        login({
                            email: values.email,
                            password: values.password,
                        }).then((res) => {
                            console.log(res);
                            setToken(res.token);
                        }).catch((err) => {
                            setErrorMessage(parseErrorMsg(err));
                            form.setErrors({ email: true, password: true });
                        }).finally(() => {
                            setLoading(false);
                        });
                    })}
                >
                    <Flex
                        direction="column"
                        gap="xs"
                        pb={'lg'}
                        style={{
                            width: 320,
                            margin: '0 auto',
                        }}
                    >
                        <LoadingOverlay visible={loading} overlayBlur={2} />
                        {
                            errorMessage !== "" && (
                                <Alert icon={<IconAlertCircle size="1rem" />} title="Error!" color="red" radius="sm">
                                    {errorMessage}
                                </Alert>
                            )
                        }
                        <div id="g_id_onload"
                            data-client_id="1027951851732-7homs21qhrfskn7m7nst8h9um4q0otj4.apps.googleusercontent.com"
                            data-context="signin"
                            data-ux_mode="popup"
                            data-login_uri="https://rocket.qarar.me/wp-json/qarar-app/v1/ar/auth/google/redirect?redirect=https://rocket.qarar.me"
                            data-auto_prompt="false">
                        </div>

                        <div className="g_id_signin"
                            style={{
                                overflow: 'hidden',
                                borderRadius: 4,
                            }}
                            data-type="standard"
                            data-shape="rectangular"
                            data-theme="filled_blue"
                            data-text="signin_with"
                            data-size="large"
                            data-logo_alignment="left">
                        </div>
                        <TextInput
                            placeholder={'Email' as string}
                            label={'Email' as string}
                            variant="filled"
                            size="sm"
                            icon={<IconAt size={20} />}
                            {...form.getInputProps('email')}
                        />
                        <PasswordInput
                            placeholder={'Password' as string}
                            label={'Password' as string}
                            variant="filled"
                            size="sm"
                            icon={<IconAsterisk size={20} />}
                            {...form.getInputProps('password')}
                        />
                        <Link to={'/forgot'}>
                            <Text align="right" size="xs">
                                {'Forgot password?'}
                            </Text>
                        </Link>
                        <Button
                            variant="gradient"
                            type="submit"
                            size={btnSize}
                            mt={'sx'}
                        >
                            {'Sign In'}
                        </Button>
                    </Flex>
                </form>
            </WarperLoginForm>
        </CenterFocus>
    );

}