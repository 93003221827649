import { Box, Button, Grid, PasswordInput, Text, TextInput, Title } from '@mantine/core'
import { useForm } from '@mantine/form';
import { deleteUser, updateUser } from 'api/fetch/user';
import { User } from 'api/types';
import { useState } from 'react';
import { parseErrorMsg } from 'utils';
import { modals } from '@mantine/modals';

export default function CategoryNew({
    user,
    onUpdate,
}: {
    user: User
    onUpdate?: () => void
}) {

    const form = useForm({
        initialValues: {
            name: user.name || '',
            email: user.email || '',
            password: '',
        } as {
            name: string
            email: string
            password?: string
        }
    });

    const [loading, setLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);

    return (
        <Box>
            <Box
                p={'md'}
                sx={theme => ({
                    maxWidth: 600,
                    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
                })}
            >
                <form onSubmit={form.onSubmit((values) => {
                    setLoading(true);
                    // if user didn't change password, remove it from values
                    if (!values.password) {
                        delete values.password;
                    }
                    updateUser({
                        id: user.id,
                        ...values,
                    }).then(() => {
                        onUpdate && onUpdate();
                        form.reset();
                    }).catch((error) => {
                        alert(parseErrorMsg(error));
                    }).finally(() => {
                        setLoading(false);
                    });
                })}>
                    <Grid>
                    <Grid.Col span={12}>
                            <TextInput
                                radius={0}
                                label="Full Name"
                                {...form.getInputProps('name')}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <TextInput
                                radius={0}
                                label="Email"
                                {...form.getInputProps('email')}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <PasswordInput
                                radius={0}
                                label="Password"
                                {...form.getInputProps('password')}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <Button
                                type="submit"
                                radius={0}
                                fullWidth
                                loading={loading}
                            >
                                Save
                            </Button>
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <Button
                                radius={0}
                                fullWidth
                                loading={deleteLoading}
                                disabled={loading}
                                color="red"
                                variant="outline"
                                onClick={() => {
                                    // show confirmation modal
                                    setDeleteLoading(true);
                                    modals.openConfirmModal({
                                        title: 'Please confirm deletion',
                                        children: (
                                            <Text size="sm">
                                                You will not be able to recover this user! please confirm deletion or cancel action.
                                            </Text>
                                        ),
                                        labels: { confirm: 'Delete', cancel: 'Cancel' },
                                        onCancel: () => {
                                            setDeleteLoading(false);
                                        },
                                        onConfirm: () => {
                                            deleteUser({
                                                id: user.id,
                                            }).then(() => {
                                                onUpdate && onUpdate();
                                            }).catch((error) => {
                                                alert(parseErrorMsg(error));
                                            }).finally(() => {
                                                setDeleteLoading(false);
                                            });
                                        },
                                    });

                                }}
                            >
                                Delete
                            </Button>
                        </Grid.Col>
                    </Grid>
                </form>
            </Box>
        </Box>
    );

}