// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

[dir=rtl] .search-box {
    direction: rtl;
}

.search-box input {
    text-align: center;
}

.header {
    padding: .5rem 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    -webkit-backdrop-filter: blur(7px);
            backdrop-filter: blur(7px);
    background-color: #1A1A1DD6;
    z-index: 100;
}


.layout-CenterFocus-children__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh);
}

@media screen and (max-width: 768px) {
    body {
        padding-bottom: 3rem;
    }
}

@media screen and (max-width: 400px) {
    .mantine-Container-root {
        padding-left: .5rem;
        padding-right: .5rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/assets/App.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT;;kBAEc;IACd,mCAAmC;IACnC,kCAAkC;AACtC;;AAEA;IACI;iBACa;AACjB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,kCAA0B;YAA1B,0BAA0B;IAC1B,2BAA2B;IAC3B,YAAY;AAChB;;;AAGA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,uBAAuB;AAC3B;;AAEA;IACI;QACI,oBAAoB;IACxB;AACJ;;AAEA;IACI;QACI,mBAAmB;QACnB,oBAAoB;IACxB;AACJ","sourcesContent":["body {\r\n    margin: 0;\r\n    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\r\n        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\r\n        sans-serif;\r\n    -webkit-font-smoothing: antialiased;\r\n    -moz-osx-font-smoothing: grayscale;\r\n}\r\n\r\ncode {\r\n    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\r\n        monospace;\r\n}\r\n\r\n[dir=rtl] .search-box {\r\n    direction: rtl;\r\n}\r\n\r\n.search-box input {\r\n    text-align: center;\r\n}\r\n\r\n.header {\r\n    padding: .5rem 0;\r\n    position: fixed;\r\n    top: 0;\r\n    left: 0;\r\n    width: 100%;\r\n    backdrop-filter: blur(7px);\r\n    background-color: #1A1A1DD6;\r\n    z-index: 100;\r\n}\r\n\r\n\r\n.layout-CenterFocus-children__container {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: center;\r\n    min-height: calc(100vh);\r\n}\r\n\r\n@media screen and (max-width: 768px) {\r\n    body {\r\n        padding-bottom: 3rem;\r\n    }\r\n}\r\n\r\n@media screen and (max-width: 400px) {\r\n    .mantine-Container-root {\r\n        padding-left: .5rem;\r\n        padding-right: .5rem;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
