import { ActionIcon, Box, Button, Drawer, Flex, Grid, MultiSelect, TextInput, Title } from '@mantine/core'
import { useForm } from '@mantine/form';
import { IconPlus } from '@tabler/icons-react';
import { newOpinion } from 'api/fetch/opinion';
import { useQueryCategory } from 'api/hook/category';
import { Category } from 'api/types';
import { useState } from 'react';
import { parseErrorMsg } from 'utils';
import CategoriesNew from './CategoriesNew';
import { useDisclosure } from '@mantine/hooks';

export default function OpinionNew({
    onUpdate,
}: {
    onUpdate?: () => void
}) {

    const form = useForm({
        initialValues: {
            title_krd: '',
            title_en: '',
            title_ar: '',
            categories: [],
        },
        validate: {
            categories: (value) => value.length < 1 && 'Select at least one category',
        },
    });

    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');

    const categoryQuery = useQueryCategory({
        offset: 0,
        limit: 100,
        search,
    });

    const [openedNew, { open: openNew, close: closeNew }] = useDisclosure(false);

    return (
        <Box>
            <Box
                p={'md'}
                sx={theme => ({
                    maxWidth: 600,
                    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
                })}
            >
                <form onSubmit={form.onSubmit((values) => {

                    if (!values.title_ar && !values.title_krd && !values.title_en) {
                        alert('Title is required');
                        return;
                    }

                    setLoading(true);
                    newOpinion(values).then(() => {
                        onUpdate && onUpdate();
                        form.reset();
                    }).catch((error) => {
                        alert(parseErrorMsg(error));
                    }).finally(() => {
                        setLoading(false);
                    });
                })}>
                    <Grid>
                        <Grid.Col span={12}>
                            <Title>
                                Title
                            </Title>
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <TextInput
                                radius={0}
                                label="Title in Arabic"
                                dir='rtl'
                                sx={{
                                    '& input': {
                                        textAlign: 'right',
                                    }
                                }}
                                {...form.getInputProps('title_ar')}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <TextInput
                                radius={0}
                                dir='rtl'
                                sx={{
                                    '& input': {
                                        textAlign: 'right',
                                    }
                                }}
                                label="Title in Kurdish"
                                {...form.getInputProps('title_krd')}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <TextInput
                                radius={0}
                                label="Title in English"
                                {...form.getInputProps('title_en')}
                            />
                        </Grid.Col>

                        <Grid.Col span={12}>
                            <Title>
                                Categories
                            </Title>
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <Flex>
                                <MultiSelect
                                    data={categoryQuery.isFetching ? [{
                                        value: 'loading',
                                        label: 'Loading...',
                                    }] : categoryQuery.data?.categories?.map((category: Category) => ({
                                        value: category.id,
                                        label: `${category.name_ar} - ${category.name_krd} - ${category.name_en}`,
                                    }))}
                                    placeholder="Select category"
                                    radius={0}
                                    searchable
                                    nothingFound="No category found"
                                    onSearchChange={setSearch}
                                    {...form.getInputProps('categories')}
                                    sx={{
                                        width: '100%',
                                    }}
                                />
                                <ActionIcon
                                    variant="default"
                                    sx={{
                                        height: 36,
                                        width: 36,
                                        borderLeft: 'none',
                                    }}
                                    onClick={() => {
                                        openNew();
                                    }}
                                >
                                    <IconPlus />
                                </ActionIcon>
                                    
                            </Flex>
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <Button
                                type="submit"
                                radius={0}
                                fullWidth
                                loading={loading}
                            >
                                Publish
                            </Button>
                        </Grid.Col>
                    </Grid>
                </form>
            </Box>

            <Drawer opened={openedNew} onClose={closeNew} overlayProps={{ opacity: 0.5, }} title="New Category">
                <CategoriesNew
                    onUpdate={() => {
                        closeNew();
                    }}
                />
            </Drawer>

        </Box>
    );

}