import { Navbar, Group, Code, ScrollArea, createStyles, rem } from '@mantine/core';    
import { useViewportSize } from '@mantine/hooks';
import {
    IconGauge,
    IconCategory2,
    IconBrain,
    IconUserCircle,
    IconUsers,
} from '@tabler/icons-react';
import { useQueryAccount } from 'api/hook/account';
import { UserButton, LinksGroup, BrandLogo } from 'components';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const mockdata = [
    { 
        label: 'Dashboard', 
        icon: IconGauge,
        link: '/app',
    },
    {
        label: 'Opinions',
        icon: IconBrain,
        link: '/app/o'
    },
    {
        label: 'Categories',
        icon: IconCategory2,
        link: '/app/cat'
    },
    {
        label: 'Users',
        icon: IconUsers,
        link: '/app/user'
    },
    {
        label: 'Profile',
        icon: IconUserCircle,
        link: '/app/profile'
    },
];

const useStyles = createStyles((theme) => ({
    navbar: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
        paddingBottom: 0,
    },

    header: {
        padding: theme.spacing.md,
        paddingTop: 0,
        marginLeft: `calc(${theme.spacing.md} * -1)`,
        marginRight: `calc(${theme.spacing.md} * -1)`,
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        borderBottom: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
            }`,
    },

    links: {
        marginLeft: `calc(${theme.spacing.md} * -1)`,
        marginRight: `calc(${theme.spacing.md} * -1)`,
    },

    linksInner: {
        paddingTop: theme.spacing.xl,
        paddingBottom: theme.spacing.xl,
    },

    footer: {
        marginLeft: `calc(${theme.spacing.md} * -1)`,
        marginRight: `calc(${theme.spacing.md} * -1)`,
        borderTop: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
            }`,
    },
}));

export default function NavbarNested() {
    const { classes } = useStyles();
    const links = mockdata.map((item) => <LinksGroup {...item} key={item.label} />);
    const { width } = useViewportSize();

    // user useQueryUser to get user data
    const {data: user} = useQueryAccount();
    const navigate = useNavigate();

    return (
        <Navbar height={width <= 900 ? 'calc(100% - 60px)' : '100%'} width={{ xs: 300 }} p="md" className={classes.navbar}>
            <Navbar.Section className={classes.header}>
                <Group position="apart">
                    <BrandLogo height={30} />
                    <Code sx={{ fontWeight: 700 }}>v1.1</Code>
                </Group>
            </Navbar.Section>

            <Navbar.Section grow className={classes.links} component={ScrollArea}>
                <div className={classes.linksInner}>{links}</div>
            </Navbar.Section>

            <Navbar.Section className={classes.footer} onClick={() => { navigate('/app/profile') }}>
                <UserButton
                    image={user?.profile_picture? user.profile_picture : undefined}
                    name={user?.name}
                    email={user?.email?.slice(0, 30) + (user?.email?.length > 30 ? '...' : '')}
                />
            </Navbar.Section>
        </Navbar>
    );
}