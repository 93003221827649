
import { Badge, Box, Drawer, Flex, Text, Title } from '@mantine/core'
import { useDisclosure, useToggle } from '@mantine/hooks'
import { IconPlus } from '@tabler/icons-react'
import {
    createColumnHelper,
} from '@tanstack/react-table'
import { getCategories } from 'api/fetch/category'
import { Category } from 'api/types'
import { PageHead, Table } from 'components'
import { CategoriesNew } from 'pages'
import { useState } from 'react'
import CategoriesEdit from './CategoriesEdit'


const columnHelper = createColumnHelper<Category>()

const columns = [
    columnHelper.accessor(row => row.name_ar, {
        id: 'title_ar',
        enableSorting: false,
        cell: info => (
            <Flex
                direction="column"
                py={'xs'}
                sx={theme => ({
                    minWidth: 400,
                    paddingRight: '5%',
                    '& > *': {
                        position: 'relative',
                        width: '100%',
                    },
                    '& > *:before': {
                        content: '""',
                        position: 'absolute',
                        left: 0,
                        width: '100%',
                        height: '100%',
                    },
                    '& > *:nth-child(1):before': {
                        borderRadius: '0 20px 0 0',
                        border: `1px solid ${theme.colors.dark[6]}`,
                        borderBottom: 'none',
                        borderLeft: 'none',
                        top: '50%',
                    },
                    '& > *:nth-child(2):before': {
                        borderTop: `1px solid ${theme.colors.dark[6]}`,
                        width: '105%',
                        top: '50%',
                    },
                    '& > *:nth-child(3):before': {
                        borderRadius: '0 0 20px 0',
                        border: `1px solid ${theme.colors.dark[6]}`,
                        borderTop: 'none',
                        borderLeft: 'none',
                        bottom: '50%',
                    },
                })}
            >
                {
                    [
                        info.row.original.name_ar,
                        info.row.original.name_krd,
                        info.row.original.name_en,
                    ].map((title, index) => (
                        title && (
                            <Box
                                key={index}
                                sx={theme => ({
                                    width: '100%',
                                })}
                            >
                                <Title
                                    order={4}
                                    style={{ lineHeight: 1.2 }}
                                    px="xs"
                                    py={3}
                                    sx={theme => ({
                                        backgroundColor: theme.colors.dark[7],
                                        position: 'relative',
                                        margin: '0 50px 0 0',
                                        display: 'block',
                                        width: 'max-content',
                                    })}
                                >
                                    {title}
                                    <Badge variant="light" ml="xs">
                                        {
                                            index === 0 ? 'ar' :
                                                index === 1 ? 'krd' :
                                                    index === 2 ? 'en' : ''
                                        }
                                    </Badge>
                                </Title>
                            </Box>
                        )
                    ))
                }
            </Flex>
        ),
        header: () => <span>Title</span>,
        footer: info => info.column.id,
    }),
    columnHelper.accessor(row => row.count, {
        id: 'count',
        cell: info => <Text>{info.getValue()}</Text>,
        header: () => <span>Opinions</span>,
        footer: info => info.column.id,
    }),
    // columnHelper.accessor(row => row.created, {
    //     id: 'created',
    //     cell: info => <Text>{dayjs(info.getValue()).format('YYYY-MM-DD HH:mm A')}</Text>,
    //     header: () => <span>Created At</span>,
    //     footer: info => info.column.id,
    // }),
]


export default function Categories() {

    const [openedNew, { open: openNew, close: closeNew }] = useDisclosure(false);
    const [openedEdit, { open: openEdit, close: closeEdit }] = useDisclosure(false);
    const [toggleKey, toggleData] = useToggle([true, false]);

    const [selectedCategory, setSelectedCategory] = useState<Category | null>(null);

    return (
        <Box>
            <PageHead
                title="Opinions"
                links={[
                    {
                        label: 'Create',
                        onClick: openNew,
                        icon: <IconPlus size={20} />,
                    }
                ]}
            />
            <Table
                endpoint={async (params: {
                    pageIndex: number,
                    pageSize: number,
                    sorting: any,
                    search: string,
                }) => {
                    const data = await getCategories({
                        offset: params.pageIndex * params.pageSize,
                        limit: params.pageSize,
                        order: params.sorting[0]?.desc ? 'DESC' : 'ASC',
                        orderby: params.sorting[0]?.id || 'count',
                        search: params.search,
                    });
                    return {
                        data: data.categories,
                        total: data.total,
                    };
                }}
                columns={columns}
                defaultPageSize={20}
                toggleData={toggleKey}
                cacheKey="categories"
                onRowClick={(row) => {
                    setSelectedCategory(row.original);
                    openEdit();
                }}
            />

            <Drawer opened={openedNew} onClose={closeNew} overlayProps={{ opacity: 0.5, }} title="New Category">
                <CategoriesNew
                    onUpdate={() => {
                        toggleData();
                        closeNew();
                    }}
                />
            </Drawer>

            <Drawer opened={openedEdit} onClose={closeEdit} overlayProps={{ opacity: 0.5, }} title="Edit Category">
                {
                    selectedCategory && (
                        <CategoriesEdit
                            category={selectedCategory}
                            onUpdate={() => {
                                toggleData();
                                closeEdit();
                            }}
                        />
                    )
                }
            </Drawer>
        </Box>
    );

}