import { Box, Button, Flex, Title } from "@mantine/core";
import { useNavigate } from "react-router-dom";

export default function PageHead(
    {
        title,
        links,
        children
    }: {
        title?: string
        links?: {
            label: string
            href?: string
            onClick?: () => void
            icon?: React.ReactNode
        }[]
        children?: React.ReactNode
    }
) {

    const navigate = useNavigate();

    return (
        <Flex
            justify={'space-between'}
            align={'stretch'}
            // p={'md'}
            sx={theme => ({
                borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]}`,
                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
            })}
        >
            <Title p={'1.1rem'}>
                {title}
            </Title>
            <Flex sx={{
                height: 'inherit',
            }} align={'stretch'}>
                {
                    links?.map((link, index) => (
                        <Button
                            key={index}
                            variant={'filled'}
                            size="md"
                            leftIcon={link.icon}
                            radius={0}
                            sx={{
                                height: '100%',
                            }}
                            onClick={() => {
                                if (link.href) {
                                    navigate(link.href);
                                } else if (link.onClick) {
                                    link.onClick();
                                }
                            }}
                        >
                            {link.label}
                        </Button>
                    ))
                }
                {children}
            </Flex>
        </Flex>
    )

}