export { default as App } from './App';

export { default as Dashboard } from './Dashboard';

export { default as Opinions } from './Opinions';
export { default as OpinionNew } from './OpinionNew';
export { default as OpinionEdit } from './OpinionEdit';

export { default as Categories } from './Categories';
export { default as CategoriesNew } from './CategoriesNew';
export { default as CategoriesEdit } from './CategoriesEdit';

export { default as User } from './User';
export { default as UserNew } from './UserNew';
export { default as UserEdit } from './UserEdit';

export { default as Profile } from './Profile';

export { default as LoginByEmail } from './LoginByEmail';
export { default as LoginForgotPassword } from './LoginForgotPassword';
export { default as RegisterByEmail } from './RegisterByEmail';